<div class="form-layout__two-columns">
  <dougs-form-field [noMargin]="true">
    <label for="amount" dougsFormFieldLabel>Montant TTC</label>
    <input
      dougsBlurOnEnter
      id="amount"
      type="number"
      dougsFormFieldControl
      [disabled]="accrualOperationComponentService.isUpdatingValidity$()"
      [ngModel]="operation.amount"
      (ngModelChange)="accrualOperationComponentService.updateAmount($event)"
      [ngModelOptions]="{ updateOn: 'blur' }"
    />
    <i class="fal fa-euro-sign suffix" dougsFormFieldSuffix></i>
  </dougs-form-field>
</div>
@if (accrualOperationSupplierComponentService.showSupplierInput$()) {
  <dougs-form-field [noMargin]="true">
    <label dougsFormFieldLabel>Fournisseur</label>
    <dougs-select
      #supplierInput
      maxHeight="300"
      [searchable]="true"
      searchPlaceholder="Rechercher un fournisseur"
      placeholder="Sélectionner un fournisseur"
      dougsFormFieldControl
      [disabled]="accrualOperationComponentService.isUpdatingValidity$()"
      [ngModel]="accrualOperationSupplierComponentService.selectedSupplier$()"
      (ngModelChange)="accrualOperationSupplierComponentService.selectSupplier($event)"
      bindValue="id"
    >
      @for (supplier of supplierStateService.suppliers$ | async; track 'id') {
        <dougs-select-option [value]="supplier">{{ supplier.name }}</dougs-select-option>
      }
      <div
        dougsCustomSelectOptionFooter
        class="py-8 px-16"
        (click)="accrualOperationSupplierComponentService.createSupplier($event)"
      >
        <i class="fas color-primary fa-plus-circle"></i>
        <p class="small bold color-primary-700">Ajouter un fournisseur</p>
      </div>
    </dougs-select>
  </dougs-form-field>
}
<div class="form-layout__two-columns">
  <dougs-input-datepicker
    [noMargin]="true"
    label="Date d'émission"
    [ngModel]="operation.date"
    [disabled]="accrualOperationComponentService.isUpdatingValidity$()"
    (ngModelChange)="accrualOperationComponentService.updateDate($event)"
    [ngModelOptions]="{ updateOn: 'blur' }"
  />
</div>
<div class="accrual-operation__breakdowns">
  @for (section of operation.sections; track section.id) {
    @for (breakdown of accrualOperationComponentService.groupedBreakdowns$()[section.id]; track breakdown.id) {
      @if (breakdownService.shouldShowBreakdown(breakdown)) {
        <div>
          <dougs-operation-breakdown
            [operation]="accrualOperationComponentService.operation$()"
            [section]="section"
            [breakdown]="breakdown"
            [forceDisabled]="accrualOperationComponentService.isUpdatingValidity$()"
            [isCompact]="true"
            [showCategoryLabel]="true"
          ></dougs-operation-breakdown>
        </div>
      }
    }
    <div
      class="operation-breakdowns__add-breakdown bold"
      (click)="accrualOperationComponentService.addBreakdown(operation, section.id)"
    >
      + Ajouter un détail
    </div>
    <div>
      <dougs-divider></dougs-divider>
    </div>
    <div class="operation-breakdowns__total__container compact">
      @if (!operation.allowUnbalanced) {
        @if (operation.isTotalAmountValid) {
          <i class="fas fa-check-circle valid-total"></i>
        } @else {
          <i
            class="fas fa-exclamation-circle invalid-total"
            dougsTooltip="Le total des détails n'est pas égal au total de l'opération."
          ></i>
        }
      }
      <span class="operation-breakdowns__total__amount bold ml-8">
        {{ accrualOperationComponentService.totalAmountExcludingFee$() | currency: 'EUR' }} TTC
      </span>
    </div>
  }
</div>
