import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  BlurOnEnterDirective,
  ButtonComponent,
  ControlFormFieldDirective,
  CustomSelectOptionFooterDirective,
  DividerComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  PillComponent,
  SelectComponent,
  SelectOptionComponent,
  SuffixFormFieldDirective,
  TooltipDirective,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { InvoiceOperationStatusPillPipe } from '../../../pipes/invoice-operation-status-pill.pipe';
import { InvoiceOperationStatusPipe } from '../../../pipes/invoice-operation-status.pipe';
import { OperationComponentService, OperationQueueService } from '../../../services';
import { AccrualOperationSupplierComponentService } from '../../../services/accrual-operation-supplier.component.service';
import { AccrualOperationComponentService } from '../../../services/accrual-operation.component.service';
import { HandleSlotActionComponentService } from '../../../services/handle-slot-action.component.service';
import { OperationDetailsComponentService } from '../../../services/operation-details.component.service';
import { OperationBreakdownComponent } from '../operation/operation-breakdown/operation-breakdown.component';
import { OperationDetailsAccountingLinesComponent } from '../operation/operation-details/operation-details-accounting-lines/operation-details-accounting-lines.component';
import { OperationDetailsActionsComponent } from '../operation/operation-details/operation-details-actions/operation-details-actions.component';
import { OperationVatComponent } from '../operation/operation-vat/operation-vat.component';
import { AccrualOperationAdminComponent } from './accrual-operation-admin/accrual-operation-admin.component';
import { AccrualOperationCardComponent } from './accrual-operation-card/accrual-operation-card.component';
import { AccrualOperationCtaComponent } from './accrual-operation-cta/accrual-operation-cta.component';
import { AccrualOperationFormComponent } from './accrual-operation-form/accrual-operation-form.component';

@Component({
  selector: 'dougs-accrual-operation',
  standalone: true,
  imports: [
    CommonModule,
    FormFieldComponent,
    BlurOnEnterDirective,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    SuffixFormFieldDirective,
    ReactiveFormsModule,
    FormsModule,
    InputDatepickerComponent,
    DividerComponent,
    OperationBreakdownComponent,
    TooltipDirective,
    OperationVatComponent,
    SelectComponent,
    SelectOptionComponent,
    DropdownOptionComponent,
    LoaderComponent,
    DropdownTriggerDirective,
    CustomSelectOptionFooterDirective,
    OperationDetailsAccountingLinesComponent,
    OperationDetailsActionsComponent,
    ButtonComponent,
    PillComponent,
    InvoiceOperationStatusPipe,
    InvoiceOperationStatusPillPipe,
    AccrualOperationFormComponent,
    AccrualOperationCardComponent,
    AccrualOperationAdminComponent,
    AccrualOperationCtaComponent,
  ],
  providers: [
    OperationComponentService,
    OperationQueueService,
    HandleSlotActionComponentService,
    AccrualOperationComponentService,
    AccrualOperationSupplierComponentService,
    OperationDetailsComponentService,
  ],
  templateUrl: './accrual-operation.component.html',
  styleUrl: './accrual-operation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccrualOperationComponent {
  constructor(
    public readonly accrualOperationComponentService: AccrualOperationComponentService,
    public readonly accrualOperationSupplierComponentService: AccrualOperationSupplierComponentService,
    public readonly userStateService: UserStateService,
  ) {}

  @Input({ required: true }) set operation(operation: Operation) {
    this.accrualOperationComponentService.setCurrentOperation(operation);
  }
}
