<div class="accrual-operation__header">
  <div class="accrual-operation__header__amount">
    <h2>{{ operation.amount | currency: 'EUR' : 'symbol' : '0.0-2' }}</h2>
    @if (operation.hasVat) {
      <p class="small">TVA {{ operation.vatAmount | currency: 'EUR' : 'symbol' : '0.0-2' }}</p>
    }
  </div>
  <div class="accrual-operation__header__pill">
    <dougs-pill [type]="operation.metadata?.status | invoiceOperationStatusPill"
      >{{ operation.metadata?.status | invoiceOperationStatus }}
    </dougs-pill>
  </div>
</div>
<div class="accrual-operation__labels">
  <div class="accrual-operation__labels__line">
    <p class="small">Fournisseur :</p>
    <p class="small color-primary-700">
      {{ accrualOperationSupplierComponentService.supplierAssociationSlot$()?.selectedItem?.label }}
    </p>
  </div>
  <div class="accrual-operation__labels__line">
    <p class="small">Date d’émission :</p>
    <p class="small color-primary-700">
      {{ operation.date | date: 'dd/MM/yyyy' }}
    </p>
  </div>
</div>
<div class="accrual-operation__breakdown-cards">
  @for (breakdown of accrualOperationComponentService.breakdowns$(); track 'id') {
    <div class="accrual-operation__breakdown-cards__line">
      <div class="accrual-operation__breakdown-cards__line__title p-8">
        <p class="small bold color-primary-700">{{ breakdown.categoryWording }}</p>
        <p class="small color-primary-700">{{ breakdown.amount | currency: 'EUR' : 'symbol' : '0.0-2' }}</p>
      </div>
      @for (association of breakdown.associations; track 'id') {
        <p class="small px-8">
          @for (message of association.messageParts; track $index) {
            @if (message.type === 'text') {
              <span [innerHTML]="message.text"></span>
            } @else if (message.type === 'slot') {
              <span>{{ association.slots?.[message.slotName]?.selectedItem?.inlineLabel ?? '' }}</span>
            }
          }
        </p>
      }
    </div>
  }
</div>
