import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { groupByKey } from '@dougs/core/utils';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { OperationDetailsComponentService } from './operation-details.component.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class AccrualOperationComponentService {
  constructor(
    private readonly operationComponentService: OperationComponentService,
    private readonly operationService: OperationService,
    private readonly operationDetailsComponentService: OperationDetailsComponentService,
  ) {}

  operation: WritableSignal<Operation | null> = signal<Operation | null>(null);
  operation$: Signal<Operation | null> = this.operation.asReadonly();

  groupedBreakdowns$: Signal<{ [key: string]: Breakdown[] }> = computed(() => this.handleBreakdowns(this.operation$()));
  totalAmountExcludingFee$: Signal<number> = computed(() => this.getTotalAmountExcludingFee(this.operation$()));
  mainBreakdown$: Signal<Breakdown | null> = computed(() => this.getMainBreakdown(this.operation$()));
  counterpartBreakdown$: Signal<Breakdown | null> = computed(() => this.getCounterpartBreakdown(this.operation$()));
  breakdowns$: Signal<Breakdown[]> = computed(
    () => this.operation$()?.breakdowns?.filter((breakdown) => !breakdown.isCounterpart) || [],
  );

  isUpdatingValidity: WritableSignal<boolean> = signal<boolean>(false);
  isUpdatingValidity$: Signal<boolean> = this.isUpdatingValidity.asReadonly();

  adminPanelOpened: WritableSignal<boolean> = signal<boolean>(false);
  adminPanelOpened$: Signal<boolean> = this.adminPanelOpened.asReadonly();

  setCurrentOperation(operation: Operation): void {
    this.isUpdatingValidity.set(false);
    this.operation.set(operation);
    this.operationComponentService.setCurrentOperation(operation);
  }

  updateAmount(amount: number): void {
    this.operationComponentService.updateOperation({ amount });
  }

  updateDate(date: string): void {
    this.operationComponentService.updateOperation({ date });
  }

  updateOperationValidity(validated: boolean): void {
    if (!this.isUpdatingValidity$()) {
      this.isUpdatingValidity.set(true);
      this.operationComponentService.updateOperation({ validated }, true);
      setTimeout(() => this.isUpdatingValidity.set(false), 5000);
    }
  }

  public addBreakdown(operation: Operation, sectionId: string): void {
    if (!this.isUpdatingValidity$()) {
      this.operationDetailsComponentService.onAddBreakdown(operation, sectionId);
    }
  }

  toggleAdminPanel(): void {
    this.adminPanelOpened.update((opened) => !opened);
  }

  private handleBreakdowns(operation: Operation | null): { [key: string]: Breakdown[] } {
    if (operation?.breakdowns?.length) {
      const breakdowns: Breakdown[] = this.operationDetailsComponentService.orderBreakdownByCreation(
        operation.breakdowns,
      );
      return groupByKey((breakdown: Breakdown) => breakdown.section, breakdowns);
    }
    return {};
  }

  private getTotalAmountExcludingFee(operation: Operation | null): number {
    return operation ? this.operationService.getTotalAmountExcludingFee(operation) : 0;
  }

  private getMainBreakdown(operation: Operation | null): Breakdown | null {
    return operation ? this.operationService.getMainBreakdown(operation) : null;
  }

  private getCounterpartBreakdown(operation: Operation | null): Breakdown | null {
    return operation ? this.operationService.getCounterPartBreakdown(operation) : null;
  }
}
