import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { combineLatest, concatMap, filter, lastValueFrom, Observable, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { ModalService, SelectComponent } from '@dougs/ds';
import { AssociationSlot } from '@dougs/operations/dto';
import { Supplier } from '@dougs/suppliers/dto';
import { SupplierStateService } from '@dougs/suppliers/shared';
import { AccrualOperationComponentService } from './accrual-operation.component.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class AccrualOperationSupplierComponentService {
  constructor(
    private readonly modalService: ModalService,
    private readonly companyStateService: CompanyStateService,
    private readonly supplierStateService: SupplierStateService,
    private readonly operationComponentService: OperationComponentService,
    private readonly accrualOperationComponentService: AccrualOperationComponentService,
  ) {}

  loadingSupplier: WritableSignal<boolean> = signal<boolean>(true);
  loadingSupplier$: Signal<boolean> = this.loadingSupplier.asReadonly();

  selectedSupplier: WritableSignal<Supplier | null> = signal<Supplier | null>(null);
  selectedSupplier$: Signal<Supplier | null> = this.selectedSupplier.asReadonly();
  showSupplierInput$: Signal<boolean> = computed(() => !!this.supplierAssociationSlot$() && !this.loadingSupplier$());

  supplierAssociationSlot$: Signal<AssociationSlot | null> = computed(
    () =>
      this.accrualOperationComponentService
        .counterpartBreakdown$()
        ?.associations?.find((association) => association.name === 'supplier')?.slots?.['supplier'] || null,
  );

  supplierInput?: SelectComponent;

  updateSelectedSupplier$: Observable<void> = combineLatest([
    toObservable(this.supplierAssociationSlot$),
    this.supplierStateService.suppliers$,
  ]).pipe(
    filter((object): object is [AssociationSlot, Supplier[]] => !!object?.[0] && !!object?.[1]),
    map(([associationSlot, suppliers]) =>
      suppliers.find((supplier) => supplier.id === associationSlot.selectedItem?.value),
    ),
    map((supplier) => this.selectedSupplier.set(supplier ?? null)),
  );

  refreshSuppliers$: Observable<void> = this.companyStateService.activeCompanyIdChanged$.pipe(
    tap(() => this.loadingSupplier.set(true)),
    concatMap((company) => this.supplierStateService.refreshSuppliers(company.id)),
    tap(() => this.loadingSupplier.set(false)),
  );

  async createSupplier(e: Event): Promise<void> {
    e.preventDefault();

    const { SupplierCreationModalComponent } = await import('@dougs/suppliers/ui');

    const supplierCreated: Supplier | null | undefined = (
      await lastValueFrom(this.modalService.open<Supplier>(SupplierCreationModalComponent).afterClosed$)
    ).data;
    if (supplierCreated) {
      this.selectSupplier(supplierCreated);
      this.supplierInput?.hideDropdown();
    }
  }

  selectSupplier(supplier: Supplier): void {
    this.selectedSupplier.set(supplier);

    const associationSlot: AssociationSlot | null = this.supplierAssociationSlot$();
    if (associationSlot) {
      this.operationComponentService.updateAssociation(
        this.accrualOperationComponentService.counterpartBreakdown$(),
        associationSlot,
        supplier.id,
      );
    }
  }

  setSupplierInput(supplierInput: SelectComponent): void {
    this.supplierInput = supplierInput;
  }
}
