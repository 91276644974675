import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'invoiceOperationStatus',
  standalone: true,
})
export class InvoiceOperationStatusPipe implements PipeTransform {
  transform(status?: string): string {
    if (status?.includes('pending')) {
      return 'En attente de paiement';
    } else if (status?.includes('overPaid') || status?.includes('paid')) {
      return 'Payée';
    } else if (status?.includes('partiallyPaid')) {
      return 'Partiellement payée';
    }
    return '';
  }
}
